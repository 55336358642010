@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Hind:wght@300;400;500;600;700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

/* CORRA MONTSERRA */
@font-face {
  font-family: "CorraMontserra";
  src: url("./fonts/corra-montserra/Corra\ Montserra\ Thin.otf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "CorraMontserra";
  src: url("./fonts/corra-montserra/Corra\ Montserra\ Extralight.otf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "CorraMontserra";
  src: url("./fonts/corra-montserra/Corra\ Montserra\ Light.otf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "CorraMontserra";
  src: url("./fonts/corra-montserra/Corra\ Montserra\ Regular.otf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "CorraMontserra";
  src: url("./fonts/corra-montserra/Corra\ Montserra\ Medium.otf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "CorraMontserra";
  src: url("./fonts/corra-montserra/Corra\ Montserra\ Semibold.otf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "CorraMontserra";
  src: url("./fonts/corra-montserra/Corra\ Montserra\ Bold.otf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "CorraMontserra";
  src: url("./fonts/corra-montserra/Corra\ Montserra\ Extrabold.otf")
    format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "CorraMontserra";
  src: url("./fonts/corra-montserra/Corra\ Montserra\ Black.otf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* FRANKLIN GOTHIC */
@font-face {
  font-family: "FranklinGothic";
  src: url("./fonts/franklin-gothic/franklin-gothic-thin.otf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "FranklinGothic";
  src: url("./fonts/franklin-gothic/franklin-gothic-extralight.otf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "FranklinGothic";
  src: url("./fonts/franklin-gothic/franklin-gothic-light.otf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "FranklinGothic";
  src: url("./fonts/franklin-gothic/franklin-gothic.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "FranklinGothic";
  src: url("./fonts/franklin-gothic/franklin-gothic-medium.otf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "FranklinGothic";
  src: url("./fonts/franklin-gothic/franklin-gothic-bold.otf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "FranklinGothic";
  src: url("./fonts/franklin-gothic/franklin-gothic-black.otf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* MESTIZA SANS */
@font-face {
  font-family: "MestizaSans";
  src: url("./fonts/mestiza-sans/mestiza-sans.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "MestizaSans";
  src: url("./fonts/mestiza-sans/mestiza-sans-bold.otf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

.title-md {
  font-size: clamp(2rem, 3.368vw, 4rem);
  line-height: 1;
}
.title-lg {
  font-size: clamp(2.5rem, 3.368vw, 4rem);
}
.button-font {
  font-size: clamp(0.875rem, 0.8115rem + 0.1587vw, 1rem);
}

.seperator {
  box-shadow: 0px 1px 6px #f5dc64;
  mix-blend-mode: difference;
  border: 1px solid #f5d7c4;
  opacity: 0.08;
  filter: blur(1px);
}

.gradient-bg {
  background: linear-gradient(
    180deg,
    #15171c 15%,
    #1f2329 30%,
    #2b3138 45%,
    #1f2329 60%,
    #15171c 75%,
    #1f2329 90%,
    #15171c 100%
  );
}

body {
  font-family: "FranklinGothic", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  background-color: #15171c;
}

.cart-items-images img:nth-child(2) {
  position: relative;
  left: -10px;
  margin-right: -10px;
}
.cart-items-images img:nth-child(3) {
  position: relative;
  left: -20px;
  margin-right: -20px;
}

.cross-cursor {
  cursor: url("./assets/cross-cursor.svg"), auto;
  -webkit-cursor: url("./assets/cross-cursor.svg"), auto;
  -moz-cursor: url("./assets/cross-cursor.svg"), auto;
}

.header-menu {
  @apply font-[500] cursor-pointer px-4 py-2 rounded-full hover:bg-formisBlack hover:text-kitsaWhite transition-colors;
}

.title-sm-mestiza {
  @apply text-kitsaWhite text-sm font-[MestizaSans];
}
.xs-mestiza {
  @apply text-kitsaWhite text-xs font-[MestizaBold];
}
.title-sm-franklin {
  @apply text-kitsaGreen text-sm font-[FranklinGothic];
}
.xs-franklin {
  @apply text-kitsaWhite text-xs font-[FranklinGothic];
}

.eta-shadow {
  text-shadow: 2px 2px 10px #f1edeb;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 768px) {
  .countdown-bg-size {
    background-size: 90rem;
    aspect-ratio: 16/9;
  }
}

.slide-up {
  @apply translate-y-0 bottom-0 left-0;
}
.slide-down {
  @apply translate-y-[110%] bottom-0 left-0;
}

/* Hide scrollbars */
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.address-input {
  @apply px-4 py-4 bg-formisWhite text-sm font-bold text-formisBlack outline-none w-full rounded-[10px];
}

.zigzag-shape {
  clip-path: polygon(
    0 0,
    100% 0,
    100% 80%,
    75% 70%,
    62.5% 80%,
    50% 70%,
    37.5% 80%,
    25% 70%,
    0 80%
  );
}

.waiting-bg {
  background: transparent
    linear-gradient(
      180deg,
      #15171c 0%,
      rgba(32, 48, 64, 0.75) 50%,
      rgba(32, 48, 64, 0.6) 52%,
      #15171c 66%,
      #15171c 89%,
      #15171c 100%
    )
    0% 0% no-repeat padding-box;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------ */

/* LOADERS */
/* HTML: <div class="loader"></div> */

.loader {
  width: 42.5px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #15171c 90%, #f1edeb);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
.loader3 {
  width: 42.5px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #f1edeb 90%, transparent);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
.loader4 {
  width: 42.5px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #f1edeb 90%, transparent);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.loader2 {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid #f1edeb;
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}
@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }
  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }
  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}
@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.bounce {
  animation: bounce 0.5s ease;
}